import React from 'react';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements'
import Icon1 from '../../images/azure.png';
import Icon2 from '../../images/voip.jpeg';
import Icon4 from '../../images/website.jpg';

const Services = () => {
  
  return (
    <ServicesContainer id="services">
      <ServicesH1>Meine Dienstleistungen</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Infrastruktur Optimierung</ServicesH2>
          <ServicesP>Ich kann Ihnen helfen, Ihre Infrastruktur auf die modernste Umgebung zu bringen. Mit Microsoft Azure kann ich Ihre physische Infrastruktur ablösen. </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Telefonanlage</ServicesH2>
          <ServicesP>Sie Verfügen noch über eine ISDN Telefonanlage und möchten Ihre Telefonie in der Cloud Betreiben? Sie proftitieren von allen Cloud Funktionen und können Ihre Anlage von jedem Standort aus verwenden. </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Eigenentwicklung</ServicesH2>
          <ServicesP>Sie haben spezielle Wünsche und möchten z.B. Prozesse automatisieren oder Logiken zwischen Systemen aufbauen? Mit Hilfe modernster Programmiersprachen, kann ich Ihnen Ihre Wünsche erfüllen.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
