import styled from "styled-components";

export const Container = styled.div`
    padding-top: 20px;
    padding-left: 2rem;
`

export const TopLine = styled.h1`
    color: black;
    justify-content: left;
    margin-top:2rem;
`

export const ButtonContainer = styled.div`
    width: 100px;
    margin-top: 2rem;
`