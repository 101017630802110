import React, {useState, useRef} from 'react';
import { 
  ContactmeContainer,
  ContactmeH1,
  AboutUsContainer,
  TextContainer,
StyledContactForm }
 from './ContactmeElements';
 import emailjs from '@emailjs/browser';
 import { Button } from '../ButtonElement';
 import { useNavigate } from 'react-router-dom';

const Contactme = () => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  const [MathQ, setMathQ] = useState(0);
  const [Solution] = useState(10);
 
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Ergebnis: " + Number(MathQ));
    if(Number(MathQ) === Solution){
        emailjs.sendForm(
            process.env.REACT_APP_SERVICE, 
            process.env.REACT_APP_TEMPLATE, 
            form.current, 
            process.env.REACT_APP_KEY)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset();
      }else{
        alert('Wrong answer! Please try it again.')
      }
    }

  return (
    <ContactmeContainer id="contactme">
      <ContactmeH1>Kontaktieren Sie mich unverbindlich</ContactmeH1>
        <AboutUsContainer>
                <TextContainer>
                    <div>Softwaresollution</div>< br></br>
                    <div>Strasshoferstraße 25/1/1</div>< br></br>
                    <div>2620 Wartmannstetten</div>< br></br>
                    <div>Tel: +43 660 165 0720</div>< br></br>
                    <div>Mail: info@softwaresolution.at</div> < br></br>< br></br>< br></br>< br></br>< br></br>< br></br>< br></br>
                    <Button onClick={navigateToHome} to='home'>Home</Button>
                </TextContainer>
                <StyledContactForm>
                    <form ref={form} onSubmit={sendEmail}>
                        <label>Name</label>
                        <input type="text" name="user_name" />
                        <label>Email</label>
                        <input type="email" name="user_email" />
                        <label>Message</label>
                        <textarea name="message" />
                        <div>7+3=</div><input  onChange={e => setMathQ(e.target.value)}/>
                        <input type="submit" value="Send"/>
                    </form>
                </StyledContactForm>
          </AboutUsContainer>
    </ContactmeContainer>
  )
}

export default Contactme;