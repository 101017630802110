import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Terms from './components/Terms/Terms.js';
import Contactme from './components/Contactmeform/index.js';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact/>
        <Route path="/terms" element={<Terms />} exact/>
        <Route path="/contactme" element={<Contactme />} exact/>
      </Routes>
    </Router> 
  );
};

export default App;
