import React from 'react';
import { Button } from '../ButtonElement';
import { Container, TopLine, ButtonContainer } from './TermsElements';
import { useNavigate } from 'react-router-dom';
const Terms = () => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  return (
    <>
      <Container>
      <TopLine>Impressum</TopLine><br />
        <ul>
            <h3>Verantwortlich für den Inhalt dieser Seiten:</h3><br />
            <div>Information gemäß §5 E-Commerce-Gesetz und Offenlegung gemäß § 25 Mediengesetz</div><br />
            <h4>Diensteanbieter und Medieninhaber:</h4>
            <div>Ralph-Peter Zillbauer</div>
            <div>Strasshoferstraße 25/1/1, 2620 Wartmannstetten</div>
            <div>Telefon: +43 660 165 0720</div>
            <div>https://softwaresolution.at</div>
            <div>info@brettner.at</div>
        </ul>
        <ButtonContainer>
          <Button onClick={navigateToHome} to='home'>Home</Button>
        </ButtonContainer>
      </Container>   
    </> 
  )
}

export default Terms;