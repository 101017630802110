import styled from 'styled-components'

export const ContactmeContainer = styled.div`
  height: 800px;
  background: white;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
 `
 export const ContactmeH1 = styled.h1`
 display: flex;
 justify-content: center;
  font-size: 2.5rem;
  color: black;
  margin-bottom: 64px;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`

export const Container = styled.div`
    padding-top: 80px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
`
export const TopLine = styled.h1`
    color: red;
    justify-content: left;
    margin-top:2rem;
    text-align: center;
    padding-bottom: 35px;
`

export const AboutUsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px;
    height: 100%;
    margin: 0;
    padding: 0;
    position:relative;
    
    @media screen and (max-width: 480px){
        grid-template-columns: repeat(1, 1fr)
    }
`

export const TextContainer = styled.div`
width: 100%;
font-size: 20px;
color: black;
`

export const Img = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: bottom;
`
export const VideoBg = styled.video`
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const ContactUsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin: 0;
    padding: 0;
    position:relative;
    
    @media screen and (max-width: 480px){
        grid-template-columns: repeat(1, 1fr)
    }
`

export const StyledContactForm = styled.div`
  width: 100%;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 20px;
    input {
      width: 100%;
      
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: black;
      color: white;
      border-radius: 50px;
      padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
      color: ${({dark}) => (dark ? '#010606' : '#fff')};
      font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
      white-space: nowrap;
      &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#01BF71' )};
    }

    }
  }
`;